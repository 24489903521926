#carouselExampleInterval {
    text-align: center;
    padding-top: 10px;
    height: 200px;
}

.btn-banner {
    background-color: #a3a3a3;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 20px;
    margin: 10% 0% 0% 10%;
    font-size: medium;
    width: 200px;
}

.lblFlamaph {
    text-align: center;
    padding-top: 30px;
}

.lblFrase {
    text-align: center;
    padding-top: 20px;
}

.lblObra {
    text-align: center;
    padding-top: 30px;
    color: #000000;
}

.carousel-inner {
    text-align: justify;
    width: 100%;
    padding: 10%;
    margin-left: 10%;
}

.container a {
    color: #000000;
}

.container a:hover {
    color: #d82629;
}