#features {
    margin-top: 20%;

}

.feature-box {
    text-align: center;
    color: #d82629;
}

.feature-box:hover {
    color: #347493;
}