.menu {
    width: 100%;
    background-color: #347493;
    background-size: contain;
    background-repeat: repeat-x;
}

.social {
    padding: 0% 0% 0% 2%;
}

.social a {
    color: #000000;
    margin-left: 20px;
}

.social a:hover {
    color: #a3a3a3;
}

.list-inline-item {
    margin-top: 1%;
}

.txtTexto {
    margin-left: 50px;
    color: #000000;
}

.txtFone {
    margin-left: 50px;
    color: #000000;
}


.txtCNPJ {
    margin-left: 10px;
    color: #000000;
}


h1,
h2,
h3,
h4,
h5 {
    line-height: 1.5;
}

p {
    color: #347493;
}

/* NAVBAR */

.navbar {
    background-color: #0d6efd;

}


/* BANNER */

#banner {
    background-color: #ffffff;

    color: #ffffff;
    padding: 15% 10% 5% 5%;
}

#banner img {
    width: 120%;
}

.btn-banner {
    margin: 8% 3% 5% 0;
}

/* FEATURES */

#features {
    padding: 7% 5%;
}

.feature-box {
    text-align: center;
    padding: 5%;
}

.icon {
    color: #ffffff;
    margin-bottom: 15px;
}


/* TESTEMUNHOS */

#testemunho {
    background-color: #f3f3f3;
    color: #323338;
    padding: 15% 10% 5% 5%;
    text-align: center;
}

#testemunho img {
    border-radius: 50%;
    width: 40%;
    margin: 20px;
}

/* HISTORIA */

#historia {
    background-color: #ffffff;
    color: #000000;
    padding: 15% 10% 5% 5%;
    /*    text-align: center;*/
}

#historia img {
    width: 100%;
}


/* FOTOS */

#fotos {
    background-color: #f3f3f3;
    color: #323338;
    padding: 20% 20%;
    text-align: center;
}

#fotos img {
    border-radius: 60%;
    width: 40%;
    margin: 20px;
}


/* PRECOS */

#preco .titulo {
    margin-top: 10%;
}

#preco {
    padding: 5% 15% 8%;
    color: #323338;
}


/* FOOTER */

#footer {
    padding: 3% 15%;
    text-align: center;
    background-color: #aaaaaa;
}

.whatsapp-link {
    position: fixed;
    width: 276px;
    height: 73px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 3px #888;
    z-index: 1000;
}