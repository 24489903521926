.rodape {
    width: 100%;
    height: 100px;
    background-color: #347493;
    background-size: contain;
    background-repeat: repeat-x;

}

.menu-rodape {
    padding: 10px;
    padding: 0% 0% 0% 5%;

}

.menu-rodape a {
    color: #000000;
    margin-left: 25px;
}

.menu-rodape a:hover {
    color: #a3a3a3;
}


.contato a {
    color: #000000;
    margin-right: 25px;
}

.conato a:hover {
    color: #a3a3a3;
}

.h6 {
    color: #000000;
    margin-right: 25px;
}

.h6 :hover {
    color: #347493;
}

/* FOOTER */

#footer {
    text-align: center;
    background-color: #aaaaaa;
}