/* PRECOS */


.container {
    margin-top: 20px;
}

.card-header {
    background-color: #347493;
}

#preco .titulo {
    margin-top: 10%;
}

#preco {
    padding: 5% 15% 8%;
    color: #323338;
}

.text-center {
    margin-top: 20px;
}